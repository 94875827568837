import { IPoint } from "..";
import dataCar2008 from "./dataCar2008";
import dataCar208 from "./dataCar208";
import dataCar508 from "./dataCar508";
import dataCar3008 from "./dataCar3008";
import dataCar308 from "./dataCar308";
import dataCar408 from "./dataCar408";
import dataCarRifter from "./dataCarRifter";
import dataCar5008 from "./dataCar5008";

export type TypeDataCarColor = {
    dir: string
    colorHex: string
    title: string
    featured?: boolean
    disabled?: boolean
}

export type TypeDataCarPin = {
    id: string
    posXPerc: number
    posYPerc: number
    coords: IPoint
    speechKeywords: string
}

export type TypeDataCarPinFrame = {
    index: number
    pins: TypeDataCarPin[]
}

export type TypeDataGalleryItem = {
    source: string
    title?: string
    type?: "image" | "video" | "youtube"
}

export interface IDataCar {
    id: string
    urlPageId: string
    title: string
    shortTitle: string
    colors: TypeDataCarColor[]
    ambienceColors?: string[]
    infoLink: string
    broshureLink: string
    otherPackagesLink: string
    appointmentLink: string
    galleryPhotos: TypeDataGalleryItem[]
    videos: TypeDataGalleryItem[]
    featuredPinFrameIndex?: number
    pinFrames: TypeDataCarPinFrame[]
    pinsInterior: TypeDataCarPin[]
    interiorImageNames: string[]
    noCarLights?: boolean
}

export const APPOINTMENT_LINK = "https://talep.peugeot.com.tr/bilgi-talebi/{car_path}?utm_source=online-showroom&utm_medium=EMP-EMD&utm_campaign=bilgi-alın_TR_AP_VN_Launch_EMP-EMD_PC_LDS_CLICKS_NA_NA&partner_uid=HashedMail"

export const DEFAULT_CAR_INDEX = 5;
export const DataCarList = [
    dataCar208, dataCar308, dataCar408,
    dataCar508, dataCar2008, 
    dataCar3008, dataCarRifter
    // dataCar5008, 
];
